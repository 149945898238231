import { CreateActionParams } from './actions';

export type GetCode = () => string;

export function createGetCode({
  getControllerState,
}: CreateActionParams): GetCode {
  return () => {
    const [state] = getControllerState();
    return state.code;
  };
}
