import { CodeEditorState } from '../types';
import { ActionFactoryParams } from '../utils/ControlledComponent.types';
import { GetCode, createGetCode } from './getCode';
import { GetOutput, createGetOutput } from './getOutput';
import { InitPyodide, createInitPyodide } from './initPyodide';
import { RunCode, createRunCode } from './runCode';
import { SetCode, createSetCode } from './setCode';
import { SetOutput, createSetOutput } from './setOutput';

export interface CreateActionParams
  extends ActionFactoryParams<CodeEditorState> {}

export type CodeEditorActions = {
  setCode: SetCode;
  getCode: GetCode;
  setOutput: SetOutput;
  getOutput: GetOutput;
  runCode: RunCode;
  initPyodide: InitPyodide;
};

export const createCodeEditorActions = (
  actionFactoryParams: ActionFactoryParams<CodeEditorState>,
): CodeEditorActions => {
  return {
    setCode: createSetCode(actionFactoryParams),
    getCode: createGetCode(actionFactoryParams),
    getOutput: createGetOutput(actionFactoryParams),
    setOutput: createSetOutput(actionFactoryParams),
    runCode: createRunCode(actionFactoryParams),
    initPyodide: createInitPyodide(actionFactoryParams),
  };
};
