import { CreateActionParams } from './actions';

export type SetOutput = (output: string) => void;

export function createSetOutput({
  getControllerState,
}: CreateActionParams): SetOutput {
  return async (output: string) => {
    const [, setState] = getControllerState();
    setState({
      output,
    });
  };
}
