import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { CodeEditorApi } from './codeEditorApi/codeEditorApi';
import { createControlledComponent } from './utils/ControlledComponent';
import { CodeEditorActions, createCodeEditorActions } from './actions/actions';
import { CodeEditorState } from './types';

const createController: CreateControllerFn = async ({ flowAPI: flowApi }) => {
  let rerender: (
    propsToUpdate: Partial<CodeEditorState | { actions: CodeEditorActions }>,
  ) => void = () => {};
  let controllerActions: CodeEditorActions;
  let codeEditorApi: CodeEditorApi;
  // For more info about controller structure,
  // check the docs: https://bo.wix.com/pages/yoshi/docs/editor-flow/structure-api/component/ooi-component#controller
  return {
    async pageReady() {
      const {
        controllerConfig,
        translations: { t },
        reportError,
        httpClient,
        experiments,
      } = flowApi;

      codeEditorApi = new CodeEditorApi();

      const { render, actions } = await createControlledComponent<
        CodeEditorState,
        CodeEditorActions
      >({
        controllerConfig,
        initialState: {
          code: ``,
          output: '',
        },
        actionsFactory: createCodeEditorActions,
        flowApi,
        codeEditorApi,
      });
      rerender = render;
      controllerActions = actions;
    },
    exports() {
      return {
        async setCode(code: any) {
          controllerActions.setCode(code);
        },
        async checkResult(runArguments: string) {
          const res = await controllerActions.runCode(
            controllerActions.getCode(),
            { runArguments, resetLogs: true },
          );
          return res;
        },
        async checkInput(input: string) {
          const res = await controllerActions.runCode(
            controllerActions.getCode(),
            { input, resetLogs: true },
          );
          return res;
        },
      };
    },
  };
};

export default createController;
