import { CreateActionParams } from './actions';

export type SetCode = (code: string) => void;

export function createSetCode({
  getControllerState,
}: CreateActionParams): SetCode {
  return async (code: string) => {
    const [, setState] = getControllerState();
    setState({
      code,
    });
  };
}
