import { loadPyodide } from 'pyodide';

export const initializePyodide = async () => {
  const pyodide = await loadPyodide({
    indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.24.1/full/',
  });

  let lastRunPrints = '';
  pyodide.registerJsModule('customPrint', {
    print: (prompt: string) => {
      return new Promise<void>((resolve) => {
        lastRunPrints += prompt + '\n';
        resolve();
      });
    },
  });

  pyodide.runPython(`
import customPrint
def print(prompt=""):
    return customPrint.print(prompt)
    `);

  pyodide.runPython(`
import io, sys
sys.stdout = io.StringIO()
  `);

  pyodide.runPython(`
from time import time
infinite_loop_watchdog_times = {}
def infinite_loop_watchdog(loop_id):
    if loop_id not in infinite_loop_watchdog_times:
        infinite_loop_watchdog_times[loop_id] = time()
    elif time() - infinite_loop_watchdog_times[loop_id] >= 2:
        raise Exception("Infinite loop")

    return True
`);

  const updateWhileToSafeWhile = (code: string) => {
    return code
      .split('\n')
      .map((line, index) => {
        if (line.includes('while ')) {
          return line.replace(
            'while ',
            `while infinite_loop_watchdog("${Date.now()}${index}") and `,
          );
        }
        return line;
      })
      .join('\n');
  };

  const run = async (code: string) => {
    lastRunPrints = '';
    await pyodide.runPythonAsync(updateWhileToSafeWhile(code));
    const consoleLogs = pyodide.runPython('sys.stdout.getvalue()');
    return {
      lastRunPrints,
      consoleLogs,
    };
  };

  const runWithInput = async (code: string, input: string) => {
    pyodide.runPython('import io, sys');
    pyodide.runPython(
      `sys.stdin = io.StringIO("${input.replace(/\n/g, '\\n')}")`,
    );

    lastRunPrints = '';
    await pyodide.runPythonAsync(updateWhileToSafeWhile(code));
    const consoleLogs = pyodide.runPython('sys.stdout.getvalue()');
    return {
      lastRunPrints,
      consoleLogs,
    };
  };

  return {
    run,
    runWithInput,
  };
};
