import { loadPyodide } from 'pyodide';
import { CreateActionParams } from './actions';
import { initializePyodide } from '../utils/initializePyodide';

export type RunCode = (
  code: string,
  options: {
    runArguments?: string;
    input?: string;
    resetLogs?: boolean;
  },
) => Promise<string>;

export function createRunCode({
  getControllerState,
  pyodide,
}: CreateActionParams): RunCode {
  return async (
    code: string,
    options: {
      runArguments?: string;
      input?: string;
      resetLogs?: boolean;
    } = {
      resetLogs: true,
    },
  ) => {
    if (!pyodide) {
      pyodide = await initializePyodide();
    }

    const [state] = getControllerState();

    let logs = options.resetLogs ? '' : `${state.output}\n`;
    try {
      let codeTorRun = code;
      if (options.runArguments) {
        codeTorRun += `\n${options.runArguments}`;
      }

      let scriptRes;
      if (options.input) {
        scriptRes = await pyodide.runWithInput(code, options.input);
      } else {
        scriptRes = await pyodide.run(codeTorRun);
      }

      logs += scriptRes.lastRunPrints;
    } catch (err: any) {
      logs += err.message.split('\n').slice(6).join('\n');
    }

    return logs.trim();
  };
}
