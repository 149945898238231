import { CreateActionParams } from './actions';

export type GetOutput = () => string;

export function createGetOutput({
  getControllerState,
}: CreateActionParams): GetOutput {
  return () => {
    const [state] = getControllerState();
    return state.output;
  };
}
