import { loadPyodide } from 'pyodide';
import { CreateActionParams } from './actions';
import { initializePyodide } from '../utils/initializePyodide';

export type InitPyodide = () => Promise<void>;

export function createInitPyodide({
  pyodide,
}: CreateActionParams): InitPyodide {
  return async () => {
    if (!pyodide) {
      pyodide = await initializePyodide();
    }
  };
}
